import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames/bind'
import { graphql } from 'gatsby'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Col, Row } from 'react-simple-flex-grid'

import { Container, Heading, Text, BlueText, Button } from '_atoms'
import { getCaseStudies } from '_config/case-studies-home'
import variables from '_config/css-variables'
import { FEATURED_BRANDS, FOUNDED_BRANDS } from '_config/home-brands'
import { AppContext } from '_context'
import { useWindowSize } from '_hooks'
import BemisLogo from '_images/carousel/bemis.png'
import FinnLogo from '_images/carousel/finn.png'
import MercadoLibreLogo from '_images/carousel/meli.png'
import MerckLogo from '_images/carousel/merck.png'
import OmahaLogo from '_images/carousel/omaha.png'
import SingularUniversityLogo from '_images/carousel/singularity.png'
import SodexoLogo from '_images/carousel/sodexo.png'
import StellarLogo from '_images/carousel/stellar.png'
import TapcartLogo from '_images/carousel/tappcart.png'
import { Image } from '_atoms'
import { BrandsFixed, BrandsFluid, Hero, QuoteContainer, ServiceIcons } from '_molecules'
import { CaseSliderItem, SEO, ShowProjectByBrand, ViewportObserver } from '_organisms'
import { Layout } from '_templates'

import SquareVideo from '../images/iphoneSquareMock.mp4'
import IphoneFrame from '../images/iphoneFrameBlack.png'

import './overrides.css'
import styles from './styles.module.css'

const intlBreakLineValues = {
  span: (...chunks) => (
    <span>
      {chunks}
      <br />
    </span>
  ),
}

const cx = classNames.bind(styles)

const IndexPage = ({ data }) => {
  const [width] = useWindowSize()
  const isMobile = width <= 768

  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const { homeTitle, homeDescription, homeThumbnail } = data.contentfulPageMetatags
  const allQuotes = data.allContentfulQuote.nodes
  const lockitronCasePicture =
    data[isMobile ? 'lockitronMobile' : 'lockitron'].childImageSharp.fluid
  const suCasePicture = data[isMobile ? 'suMobile' : 'su'].childImageSharp.fluid
  const skyroamCasePicture = data[isMobile ? 'skyroamMobile' : 'skyroam'].childImageSharp.fluid
  const tapcartCasePicture = data[isMobile ? 'tapcartMobile' : 'tapcart'].childImageSharp.fluid
  const findheliCasePicture = data[isMobile ? 'findheliMobile' : 'findheli'].childImageSharp.fluid

  /* scroll back to top when scrolling on Home, going to another page and coming back */
  useEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])

  const intl = useIntl()
  const CASE_STUDIES = getCaseStudies(intl)
  const CASE_IMAGES = {
    lockitron: {
      image: lockitronCasePicture,
      alt: 'Lockitron',
      maxWidth: 552,
    },
    su: {
      image: suCasePicture,
      alt: 'Singularity University',
      maxWidth: 511,
    },
    skyroam: {
      image: skyroamCasePicture,
      alt: 'Skyroam',
      maxWidth: 1075,
    },
    tapcart: {
      image: tapcartCasePicture,
      alt: 'Tapcart',
      maxWidth: 421,
    },
    findheli: {
      image: findheliCasePicture,
      alt: 'Findheli',
      maxWidth: 599,
    },
  }
  const showcaseLogos = [
    TapcartLogo,
    SodexoLogo,
    OmahaLogo,
    SingularUniversityLogo,
    MerckLogo,
    MercadoLibreLogo,
    FinnLogo,
    BemisLogo,
    StellarLogo,
  ]

  const customHeroContent = (
    <Row className={styles.heroContent}>
      {!isMobile && (
        <Col className={styles.heroPhone}>
          <Fade distance="25%" bottom>
            <video preload="auto" loop autoPlay className={styles.heroVideo} muted controls={false}>
              <source src={SquareVideo} type="video/mp4" />
            </video>
            <Image src={IphoneFrame} alt="Iphone Frame Mock" className={styles.heroFrame} />
          </Fade>
        </Col>
      )}
      <Col>
        <Fade distance="25%" top>
          <Heading color="white" bold className={styles.heroTitle}>
            <FormattedMessage values={intlBreakLineValues} id="home.hero.title" />
            <BlueText light squared>
              .
            </BlueText>
          </Heading>
        </Fade>
        <Fade distance="25%" bottom>
          <Heading type="h2" color="white" className={styles.heroSubtitle}>
            <FormattedMessage values={intlBreakLineValues} id="home.hero.description" />
          </Heading>
        </Fade>
      </Col>
    </Row>
  )

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    500
  )

  const renderLogo = (el, index) => (
    <div key={`slide-${index}`} className={styles.slide}>
      <img src={el} alt="" />
    </div>
  )

  const withRepeatedLogos = useMemo(
    () => [
      // This is necessary because every time is showed the repeated list
      // on the same position where was started the animation, it restarts
      // and make an illusion that is infinite
      ...showcaseLogos,
      ...showcaseLogos,
    ],
    [showcaseLogos]
  )

  const Gifs = () => (
    <Row gutter={40}>
      <Col xs={6} lg={3}>
        <ServiceIcons name="uiux-design" />
        <Text align="center" size="20">
          <FormattedMessage id="home.whatWeDo.design" />
        </Text>
      </Col>
      <Col xs={6} lg={3}>
        <ServiceIcons name="mobile-dev" />
        <Text align="center" size="20">
          <FormattedMessage id="home.whatWeDo.app" />
        </Text>
      </Col>
      <Col xs={6} lg={3}>
        <ServiceIcons name="web-dev" />
        <Text align="center" size="20">
          <FormattedMessage id="home.whatWeDo.product" />
        </Text>
      </Col>
      <Col xs={6} lg={3}>
        <ServiceIcons name="nextgen" />
        <Text align="center" size="20">
          <FormattedMessage id="home.whatWeDo.nextgen" />
        </Text>
      </Col>
    </Row>
  )

  return (
    <Layout>
      <SEO
        title={homeTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={homeDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={homeThumbnail.file.url}
      />

      <main className={cx('heroBackground')}>
        <Hero
          className={styles.hero}
          contentClassName={styles.heroContent}
          customContent={customHeroContent}
        />
        <Row className={styles.container}>
          <div className={styles.slider}>
            <Row
              className={styles.slideTrack}
              style={{
                // the logo container size * the amount of them
                width: isMobile
                  ? `calc(33.3vw * ${withRepeatedLogos.length})`
                  : `calc(12.5vw * ${withRepeatedLogos.length})`,
              }}
            >
              {withRepeatedLogos.map(renderLogo)}
            </Row>
          </div>
        </Row>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-primary'])
            setNavbarLight(false)
          }}
        />
      </main>

      {CASE_STUDIES.map(caseStudy => (
        <CaseSliderItem
          key={caseStudy.name}
          name={caseStudy.name}
          title={caseStudy.children}
          color={caseStudy.color}
          link={caseStudy.link}
          linkId={caseStudy.linkId}
          fullwidth={caseStudy.isFullWidth}
          scroll={y}
          onViewport={() => {
            setNavbarColor(caseStudy.color)
            setNavbarLight(false)
          }}
        >
          <CaseSliderItem.Img
            fluid={CASE_IMAGES[caseStudy.name].image}
            alt={CASE_IMAGES[caseStudy.name].alt}
            maxWidth={CASE_IMAGES[caseStudy.name].maxWidth}
          />
        </CaseSliderItem>
      ))}

      <div className={cx('section', 'safariScrollFlickerFix')}>
        <section className={cx('sub-section', 'blueSection', 'safariScrollFlickerFix')}>
          <Container className={cx('safariScrollFlickerFix', 'blueSectionContainer')}>
            <Text className={styles.brandsSubtitle} align="center" size="24" color="white">
              <strong>
                <FormattedMessage id="home.ourWork.text1" />
              </strong>{' '}
              <FormattedMessage id="home.ourWork.text2" />
            </Text>
            <div className={styles.brandsRow}>
              {FEATURED_BRANDS.map(brand => (
                <ShowProjectByBrand
                  key={`featured-brand-${brand.name}`}
                  brandClassName={styles.pressBrands}
                  projectClassName={styles.projectBrands}
                  projects={brand.projects}
                  brand={brand.name}
                />
              ))}
            </div>
          </Container>

          <Container className={styles.safariScrollFlickerFix}>
            <Text className={styles.brandsSubtitle} align="center" size="24" color="white">
              <FormattedMessage id="home.ourProjects.text1" />
              <strong>
                {' '}
                <FormattedMessage id="home.ourProjects.text2" />{' '}
              </strong>
              <FormattedMessage id="home.ourProjects.text3" />
            </Text>
            <div className={cx('foundedByRow', 'foundedByRowDesktopVersion')}>
              {FOUNDED_BRANDS.map(brand => (
                <a
                  className={styles.foundedByBrands}
                  key={`founded-by-brand-${brand.name}`}
                  href={brand.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BrandsFixed name={brand.name} />
                </a>
              ))}
            </div>
            <div className={cx('foundedByRow', 'foundedByRowMobileVersion')}>
              {FOUNDED_BRANDS.map(brand => (
                <a
                  className={styles.foundedByBrands}
                  key={`founded-by-brand-${brand.name}`}
                  href={brand.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BrandsFluid name={brand.name} />
                </a>
              ))}
            </div>
          </Container>
          <ViewportObserver
            scroll={y}
            customViewport={{ top: 90, bottom: -200 }}
            onViewport={() => {
              setNavbarColor(variables['color-primary'])
              setNavbarLight(false)
            }}
          />
        </section>

        <section className={cx('sub-section', 'whatSetUsApartSection', 'safariScrollFlickerFix')}>
          <div className={styles.whatSetUsApartSectionBlock}>
            <div className={styles.whatSetUsApartSectionBlockText}>
              <Container>
                <Heading color="white" className={styles.whatSetUsApartTitle} type="h4">
                  <FormattedMessage id="home.whatSetUsApart" />
                  <BlueText squared> ?</BlueText>
                </Heading>
              </Container>

              <QuoteContainer
                items={allQuotes}
                isWhite
                isCenter
                className={styles.whatSetUsApartQuote}
                sliderClass={styles.whatSetUsApartQuoteSlider}
                iconsClass={styles.whatSetUsApartQuoteIcons}
                hideUserPhotoMobile
              />

              <ViewportObserver
                scroll={y}
                customViewport={{ top: 90, bottom: -200 }}
                onViewport={() => {
                  setNavbarColor(variables['color-dark-bg'])
                  setNavbarLight(false)
                }}
              />
            </div>
          </div>
        </section>

        <section className={cx('sub-section', 'whatWeDoSection', 'safariScrollFlickerFix')}>
          <Container>
            <div className={styles.whatWeDoContent}>
              <Row gutter={40}>
                <Col xs={12} sm={12} md={5} lg={4}>
                  <Heading className={styles.whatWeDoTitle} type="h3">
                    <span className={styles.whatWeDoBreak}>
                      <FormattedMessage id="home.whatWeDo.firstWord" />{' '}
                    </span>
                    <FormattedMessage id="home.whatWeDo.title" />
                    <strong>
                      {' '}
                      <FormattedMessage id="home.whatWeDo.titleLastWord" />
                      <span className={styles.squared}>?</span>
                    </strong>
                  </Heading>
                  <Button className={styles.whatWeDoDesktopButton} href="/services">
                    <FormattedMessage id="home.whatWeDo.button" />
                  </Button>
                </Col>

                <Col xs={12} sm={12} md={7} lg={8}>
                  <div className={styles.textCol}>
                    <FormattedMessage
                      id="home.whatWeDo.description"
                      values={{ p: (...chunks) => <Text size="24">{chunks}</Text> }}
                    />
                    <Gifs />
                  </div>
                </Col>
              </Row>
              <div className={styles.whatWeDoMobileButtonContainer}>
                <Button className={styles.whatWeDoMobileButton} href="/services">
                  <FormattedMessage id="home.whatWeDo.button" />
                </Button>
              </div>
            </div>
          </Container>
          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['color-white'])
              setNavbarLight(true)
            }}
          />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuotes($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      homeTitle
      homeDescription
      homeThumbnail {
        file {
          url
        }
      }
    }
    allContentfulQuote(
      filter: {
        quoteCategory: { elemMatch: { name: { eq: "Home" } } }
        fields: { localeKey: { eq: $locale } }
      }
    ) {
      nodes {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
    lockitron: file(relativePath: { eq: "lockitron-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    lockitronMobile: file(relativePath: { eq: "lockitron-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    su: file(relativePath: { eq: "su-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 511) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    suMobile: file(relativePath: { eq: "su-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    skyroam: file(relativePath: { eq: "skyroam-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 731) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    skyroamMobile: file(relativePath: { eq: "skyroam-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tapcart: file(relativePath: { eq: "tapcart-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 421) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tapcartMobile: file(relativePath: { eq: "tapcart-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    findheli: file(relativePath: { eq: "findheli-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 599) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    findheliMobile: file(relativePath: { eq: "findheli-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default IndexPage
