const FEATURED_BRANDS = [
  {
    name: 'techcrunch',
    projects: [
      { name: 'lockitron', url: 'http://techcrunch.com/tag/lockitron/' },
      { name: 'singularity-university', url: 'http://techcrunch.com/tag/singularity-university/' },
      {
        name: 'camio',
        url:
          'https://techcrunch.com/2014/02/26/camiocam-turns-any-web-or-video-camera-into-a-smart-cloud-based-monitoring-device/',
      },
      { name: 'whittl', url: 'https://techcrunch.com/tag/whittl/' },
      {
        name: 'finn',
        url:
          'https://techcrunch.com/2022/05/25/finn-raises-110m-to-expand-car-subscription-platform-in-u-s-germany/',
      },
      {
        name: 'tapcart',
        url:
          'https://techcrunch.com/2021/06/28/tapcart-a-shopify-for-mobile-apps-raises-a-50-million-series-b/',
      },
      {
        name: 'modern-age',
        url:
          'https://techcrunch.com/2021/10/20/modern-age-is-on-a-mission-to-put-people-in-the-drivers-seat-of-their-aging-journey/',
      },
    ],
  },
  {
    name: 'forbes',
    projects: [
      {
        name: 'singularity-university',
        url:
          'https://www.forbes.com/sites/brianrashid/2015/07/22/why-you-should-apply-to-singularity-university-today/#63b23342154d',
      },
      {
        name: 'skyroam',
        url: 'https://www.forbes.com/sites/dandao/2019/08/09/the-best-travel-gadgets-of-2019/',
      },
      {
        name: 'forfreedoms',
        url:
          'https://www.forbes.com/sites/addiewagenknecht/2018/03/19/how-modou-dieng-is-changing-the-oregon-art-scene/',
      },
    ],
  },
  {
    name: 'wired',
    projects: [
      {
        name: 'camio',
        url: 'https://www.wired.com/2015/03/app-lets-stalk-home/',
      },

      {
        name: 'singularity-university',
        url: 'https://www.wired.co.uk/article/on-the-exponential-curve',
      },
    ],
  },
  {
    name: 'cointelegraph',
    projects: [
      {
        name: 'stellar',
        url:
          'https://cointelegraph.com/news/mercado-bitcoin-partners-with-stellar-to-create-mvp-for-brazilian-cbdc',
      },
      {
        name: 'hyperspace',
        url:
          'https://cointelegraph.com/news/vc-roundup-animoca-leads-nft3-raise-arca-launches-nft-fund-and-alexis-ohanian-broadens-crypto-exposure',
      },
    ],
  },
  {
    name: 'coin-desk',
    projects: [
      {
        name: 'stellar',
        url:
          'https://www.coindesk.com/policy/2021/12/14/ukraine-commercial-bank-to-test-digital-currency-built-on-stellar/',
      },
      {
        name: 'lockitron',
        url:
          'https://www.wsj.com/articles/the-lock-has-evolved-open-doors-with-your-phone-1413291632',
      },
    ],
  },
  {
    name: 'cnet',
    projects: [
      {
        name: 'lockitron',
        url: 'https://www.cnet.com/reviews/lockitron-preview/',
      },
      {
        name: 'singularity-university',
        url: 'https://www.cnet.com/news/at-singularity-u-big-brains-meet-the-future/',
      },
      {
        name: 'bond',
        url: 'https://www.cnet.com/reviews/bond-review/',
      },
    ],
  },
  {
    name: 'the-verge',
    projects: [
      {
        name: 'lockitron',
        url:
          'https://www.theverge.com/2012/10/3/3447564/apigy-lockitron-preorder-keyless-lock-smartphone-iphone-android',
      },
    ],
  },
  {
    name: 'venture-beat',
    projects: [
      {
        name: 'camio',
        url:
          'https://venturebeat.com/2014/11/19/camiocam-app-turns-any-mobile-device-or-browser-into-a-home-surveillance-camera/',
      },
      {
        name: 'singularity-university',
        url: 'https://venturebeat.com/2012/07/04/inside-singularity-unversity/',
      },
      {
        name: 'skyroam',
        url:
          'https://venturebeat.com/2017/08/29/skyroam-solis-delivers-wi-fi-and-charging-on-the-move-in-over-100-countries/',
      },
    ],
  },
  {
    name: 'the-guardian',
    projects: [
      {
        name: 'singularity-university',
        url:
          'https://www.theguardian.com/technology/2012/apr/29/singularity-university-technology-future-thinkers',
      },
      {
        name: 'womensmarch',
        url:
          'https://www.theguardian.com/world/2019/jan/18/womens-march-30-countries-set-to-take-part-in-third-global-protest',
      },
      {
        name: 'forfreedoms',
        url:
          'https://www.theguardian.com/artanddesign/2018/oct/15/50-state-initiative-behind-americas-most-ambitious-public-art-project-ever',
      },
    ],
  },
  {
    name: 'tnw',
    projects: [
      {
        name: 'skyroam',
        url:
          'https://thenextweb.com/plugged/2017/08/29/skyroam-solis-review-unlimited-lte-data-100-countries-really-need/',
      },
    ],
  },
]

const FOUNDED_BRANDS = [
  { name: 'pantera', url: 'https://panteracapital.com' },
  { name: 'galaxy', url: 'https://www.galaxydigital.io' },
  { name: 'highland', url: 'https://www.hcp.com' },
  { name: 'true-ventures', url: 'https://trueventures.com' },
  { name: 'andreessen-horowitz', url: 'https://a16z.com/' },
  { name: 'gv', url: 'https://www.gv.com/' },
  { name: 'korelya', url: 'https://www.korelyacapital.com' },
  { name: 'greycroft', url: 'https://www.greycroft.com/' },
]

export { FOUNDED_BRANDS, FEATURED_BRANDS }
